import React,{ useState } from "react"
import { connectSearchBox } from "react-instantsearch-dom"

export default connectSearchBox(
    ({ refine, currentRefinement, className, onFocus }) => {
  
      let timerId = null;
      const delay = 1000;
  
      const [newRefinement, setNewRefinement] = useState(currentRefinement);
  
      const onChangeDebounced = (event) => {
        const value = event.currentTarget.value;
  
        clearTimeout(timerId);
        timerId = setTimeout(() => refine(value), delay);
  
        setNewRefinement(value);
      };


    return (
      <form className={className} onSubmit={e => { e.preventDefault(); }}>
        <div className="absolute inset-y-0 left-0 px-5 flex items-center pointer-events-none mt-1">
          <svg className="stroke-[#1D1D1B]" width="23" height="22" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg" id='search-icon'>
            <path d="M7.25 12.75C10.5637 12.75 13.25 10.0637 13.25 6.75C13.25 3.43629 10.5637 0.75 7.25 0.75C3.93629 0.75 1.25 3.43629 1.25 6.75C1.25 10.0637 3.93629 12.75 7.25 12.75Z" stroke="#1D1D1B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M14.7508 14.25L11.4883 10.9875" stroke="#1D1D1B" stroke-width="1.5" stroke-linecap="#1D1D1B" stroke-linejoin="#1D1D1B"/>
          </svg>
        </div>
        <input
          className="block w-full shadow-input mx-auto font-display hover:border-brightgreen focus:!border-brightgreen caret-brightgreen rounded-full leading-none bg-white py-6 pr-8 pl-12 text-lightblue-500 focus:text-darkblue border-[1px] focus:outline-none text-sm lg:text-lg placeholder-lightblue-500 ring-0 focus:ring-0"
          type="text"
          placeholder="What do you want to search for?"
          aria-label="Search"
          onChange={(e) => onChangeDebounced(e)}
          // onFocus={(e) => console.log('focused')}
          value={newRefinement}
          onFocus={() => (onFocus, document.getElementById("search-icon").classList.add("!stroke-brightgreen"))}
          onBlur={() => document.getElementById("search-icon").classList.remove("!stroke-brightgreen")}
        />
      </form>
    )
  }
)